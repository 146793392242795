import React, { useState } from 'react';

export function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
    robotValidation: '',
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [submitted, setSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    // Required field validation
    if (!formData.firstName) newErrors.firstName = 'Required';
    if (!formData.lastName) newErrors.lastName = 'Required';
    if (!formData.email) newErrors.email = 'Required';
    if (!formData.subject) newErrors.subject = 'Required';
    if (!formData.message) newErrors.message = 'Required';

    // Robot validation
    const robotAnswer = formData.robotValidation.trim().toLowerCase();
    if (robotAnswer !== '2' && robotAnswer !== 'two') {
      newErrors.robotValidation = 'Incorrect answer. Try again.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

    const payload = {
      uniqueHandler: 'Contact Form',
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
      submissionDate: new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }),
    };

    try {
      const response = await fetch(
        'https://webhooks.integrately.com/a/webhooks/2544a22ef06b41cf945e8e304649736d',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setSubmitted(true);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          subject: '',
          message: '',
          robotValidation: '',
        });
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  if (submitted) {
    return (
      <div className="flex items-center justify-center h-[300px] text-center">
        <div>
          <h2 className="text-2xl font-semibold text-[#c6aa6b] mb-4">Thank you for your message!</h2>
          <p className="text-gray-300">
            Our team will get back to you soon to address your inquiry.
          </p>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="bg-[#23262f] rounded-lg p-8 shadow-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <label htmlFor="firstName" className="block text-white font-medium mb-2">
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            value={formData.firstName}
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
            required
          />
          {errors.firstName && <span className="text-red-500 text-sm">{errors.firstName}</span>}
        </div>
        <div>
          <label htmlFor="lastName" className="block text-white font-medium mb-2">
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            value={formData.lastName}
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
            required
          />
          {errors.lastName && <span className="text-red-500 text-sm">{errors.lastName}</span>}
        </div>
      </div>

      <div className="mb-6">
        <label htmlFor="email" className="block text-white font-medium mb-2">
          Email Address
        </label>
        <input
          type="email"
          id="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          required
        />
        {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
      </div>

      <div className="mb-6">
        <label htmlFor="subject" className="block text-white font-medium mb-2">
          Subject
        </label>
        <input
          type="text"
          id="subject"
          value={formData.subject}
          onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
          className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          required
        />
        {errors.subject && <span className="text-red-500 text-sm">{errors.subject}</span>}
      </div>

      <div className="mb-6">
        <label htmlFor="message" className="block text-white font-medium mb-2">
          Message
        </label>
        <textarea
          id="message"
          value={formData.message}
          onChange={(e) => setFormData({ ...formData, message: e.target.value })}
          rows={4}
          className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors resize-none"
          required
        />
        {errors.message && <span className="text-red-500 text-sm">{errors.message}</span>}
      </div>

      <div className="mb-6">
        <label htmlFor="robotValidation" className="block text-white font-medium mb-2">
          What&apos;s seven minus 5? <span className="text-gray-400">(answer in numbers or text)</span>
        </label>
        <input
          type="text"
          id="robotValidation"
          value={formData.robotValidation}
          onChange={(e) => setFormData({ ...formData, robotValidation: e.target.value })}
          className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          required
        />
        {errors.robotValidation && (
          <span className="text-red-500 text-sm">{errors.robotValidation}</span>
        )}
      </div>

      <button
        type="submit"
        className="w-full bg-[#c6aa6b] hover:bg-[#b39461] text-white font-semibold py-4 px-8 rounded-lg transition-colors duration-200 shadow-lg"
      >
        Send Message
      </button>
    </form>
  );
}
