import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import router from './routes/routes';
import './index.css';

// Add error boundary for development
if (process.env.NODE_ENV === 'development') {
  const root = document.getElementById('root');
  if (root) {
    root.style.height = '100vh';
    root.style.width = '100%';
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);

