import React from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';

export function App() {
  return (
    <ErrorBoundary>
      <div className="min-h-screen flex flex-col bg-[#1a1d24]">
        <ScrollToTop />
        <Header />
        <main className="flex-grow">
          <Outlet />
        </main>
        <Footer />
      </div>
    </ErrorBoundary>
  );
}

