import React, { useState } from 'react';

export function VIPForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    birthday: '',
    femaleGuests: '',
    maleGuests: '',
    additionalInfo: '',
    termsAccepted: false,
    newsletter: true, // Default to true
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.firstName) newErrors.firstName = 'Required';
    if (!formData.lastName) newErrors.lastName = 'Required';
    if (!formData.email) newErrors.email = 'Required';
    if (!formData.phone) newErrors.phone = 'Required';
    if (!formData.date) newErrors.date = 'Required';
    if (!formData.time) newErrors.time = 'Required';
    if (!formData.femaleGuests) newErrors.femaleGuests = 'Required';
    if (!formData.maleGuests) newErrors.maleGuests = 'Required';
    if (!formData.termsAccepted) newErrors.termsAccepted = 'You must accept the terms';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

    const currentTimestamp = new Date().toISOString();

    const payload = {
      formType: 'vip_table', // Unique identifier
      ...formData,
      submissionDate: currentTimestamp, // Add date/time of submission
    };

    try {
      const response = await fetch('https://webhooks.integrately.com/a/webhooks/2544a22ef06b41cf945e8e304649736d', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setIsSubmitted(true);
      } else {
        alert('Failed to submit the form. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again later.');
    }
  };

const isAllowedDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDay();
  const isNYE = dateString === '2024-12-31'; // Allow Dec 31st, 2024
  return isNYE || [3, 5, 6, 0].includes(day); // Only allow Wednesday, Friday, Saturday, Sunday, and New Year's Eve
};

  const timeSlots = [
    '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM',
    '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM',
    '12:00 AM', '12:30 AM', '1:00 AM', '1:30 AM',
  ];

  if (isSubmitted) {
    return (
      <div className="bg-[#23262f] rounded-xl p-8 shadow-2xl flex items-center justify-center min-h-[300px]">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-white">Thank you for your inquiry!</h2>
          <p className="text-gray-300 mt-4">
            Our team will contact you soon to confirm your VIP table reservation and discuss next steps.
          </p>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="bg-[#23262f] rounded-xl p-8 shadow-2xl">
      {/* Name Fields */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div>
          <label className="block text-white font-medium mb-2">First Name</label>
          <input
            type="text"
            value={formData.firstName}
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          />
          {errors.firstName && <span className="text-red-500 text-sm">{errors.firstName}</span>}
        </div>
        <div>
          <label className="block text-white font-medium mb-2">Last Name</label>
          <input
            type="text"
            value={formData.lastName}
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          />
          {errors.lastName && <span className="text-red-500 text-sm">{errors.lastName}</span>}
        </div>
      </div>

      {/* Contact Fields */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div>
          <label className="block text-white font-medium mb-2">Email Address</label>
          <input
            type="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          />
          {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
        </div>
        <div>
          <label className="block text-white font-medium mb-2">Phone Number</label>
          <input
            type="tel"
            value={formData.phone}
            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          />
          {errors.phone && <span className="text-red-500 text-sm">{errors.phone}</span>}
        </div>
      </div>

      {/* Birthday */}
      <div className="mb-8">
        <label className="block text-white font-medium mb-2">
          Share your DOB for a free bottle of champagne on your birthday week
        </label>
        <input
          type="date"
          value={formData.birthday}
          onChange={(e) => setFormData({ ...formData, birthday: e.target.value })}
          className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
        />
      </div>

      {/* Reservation Date & Time */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div>
          <label className="block text-white font-medium mb-2">Date of Reservation</label>
          <input
            type="date"
            value={formData.date}
            onChange={(e) => {
              if (isAllowedDate(e.target.value)) {
                setFormData({ ...formData, date: e.target.value });
                setErrors({ ...errors, date: '' });
              } else {
                setErrors({ ...errors, date: 'Select a valid date (Wed, Fri, Sat, Sun).' });
              }
            }}
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          />
          {errors.date && <span className="text-red-500 text-sm">{errors.date}</span>}
        </div>
        <div>
          <label className="block text-white font-medium mb-2">Arrival Time</label>
          <select
            value={formData.time}
            onChange={(e) => setFormData({ ...formData, time: e.target.value })}
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          >
            <option value="">Select time</option>
            {timeSlots.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
          {errors.time && <span className="text-red-500 text-sm">{errors.time}</span>}
        </div>
      </div>

      {/* Female and Male Guests */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div>
          <label className="block text-white font-medium mb-2">Number of Female Guests</label>
          <input
            type="number"
            min="0"
            value={formData.femaleGuests}
            onChange={(e) => setFormData({ ...formData, femaleGuests: e.target.value })}
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          />
        </div>
        <div>
          <label className="block text-white font-medium mb-2">Number of Male Guests</label>
          <input
            type="number"
            min="0"
            value={formData.maleGuests}
            onChange={(e) => setFormData({ ...formData, maleGuests: e.target.value })}
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
          />
        </div>
      </div>

      {/* Additional Info */}
      <div className="mb-8">
        <label className="block text-white font-medium mb-2">Additional Information</label>
        <textarea
          value={formData.additionalInfo}
          onChange={(e) => setFormData({ ...formData, additionalInfo: e.target.value })}
          className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors resize-none"
          rows={4}
          placeholder="Tell us about any special requests or what you're celebrating..."
        />
      </div>

      {/* Checkboxes */}
      <div className="space-y-4 mb-8">
        <label className="flex items-start space-x-3">
          <input
            type="checkbox"
            checked={formData.termsAccepted}
            onChange={(e) => setFormData({ ...formData, termsAccepted: e.target.checked })}
            className="mt-1"
          />
          <span className="text-white">
            I understand that this is a request for VIP Bottle Service that requires a minimum table
            spend and may require an upfront deposit.
          </span>
        </label>
        <label className="flex items-start space-x-3">
          <input
            type="checkbox"
            checked={formData.newsletter}
            onChange={(e) => setFormData({ ...formData, newsletter: e.target.checked })}
            className="mt-1"
          />
          <span className="text-white">I'd love to be occasionally emailed about special events.</span>
        </label>
      </div>

      <button
        type="submit"
        className="w-full bg-[#c6aa6b] hover:bg-[#b39461] text-white font-semibold py-4 px-8 rounded-lg transition-colors duration-200 shadow-lg"
      >
        Request VIP Table
      </button>
    </form>
  );
}
