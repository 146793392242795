import React, { useState } from 'react';

const eventTypes = [
  'Corporate Event',
  'Corporate Holiday Event',
  'Corporate Happy Hour',
  'Business Networking Event',
  'Real Estate Event',
  'Wedding Reception',
  'Fundraiser',
  'Charity Event',
  'Baby or Bridal Shower',
  'Other'
];

const partySizes = ['100-150', '150-250', '250+'];

export function EventForm() {
  const [eventType, setEventType] = useState('');
  const [partySize, setPartySize] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = {
      formType: 'private_event', // Unique identifier for this form
      eventType,
      partySize,
      eventDate,
      startTime,
      endTime,
      additionalDetails,
      firstName,
      lastName,
      phoneNumber,
      email
    };

    fetch('https://webhooks.integrately.com/a/webhooks/2544a22ef06b41cf945e8e304649736d', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then((response) => {
        if (response.ok) {
          setIsSubmitted(true); // Show success message
        } else {
          alert('Failed to submit the form. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        alert('An error occurred. Please try again later.');
      });
  };

  if (isSubmitted) {
    return (
      <div className="bg-[#23262f] rounded-xl shadow-2xl overflow-hidden p-8">
        <h2 className="text-2xl font-semibold text-white">Thank you for your inquiry!</h2>
        <p className="text-gray-300 mt-4">
          Our team will contact you soon to confirm your event details and discuss next steps.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-[#23262f] rounded-xl shadow-2xl overflow-hidden">
      <div className="bg-[#2b2e38] px-8 py-6 border-b border-neutral-700">
        <h2 className="text-2xl font-semibold text-white">Event Inquiry Form</h2>
        <p className="text-gray-400 mt-2">
          Please complete some basic information about your event and a manager will contact you ASAP.
        </p>
      </div>

      <form className="p-8 space-y-8" onSubmit={handleSubmit}>
        {/* Event Type Selection */}
        <div className="space-y-4">
          <label className="block text-lg font-medium text-white mb-4">
            What type of event are you planning?
          </label>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {eventTypes.map((type) => (
              <label
                key={type}
                className={`
                  relative flex items-center p-4 rounded-lg cursor-pointer
                  ${eventType === type ? 'bg-[#c6aa6b]/20 border-[#c6aa6b]' : 'bg-neutral-800 border-neutral-700'}
                  border-2 transition-all duration-200 hover:border-[#c6aa6b]/50
                `}
              >
                <input
                  type="radio"
                  name="eventType"
                  value={type}
                  checked={eventType === type}
                  onChange={(e) => setEventType(e.target.value)}
                  className="sr-only"
                />
                <span className="text-white">{type}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Party Size Selection */}
        <div className="space-y-4">
          <label className="block text-lg font-medium text-white mb-4">
            How large is your party?
          </label>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {partySizes.map((size) => (
              <label
                key={size}
                className={`
                  relative flex items-center p-4 rounded-lg cursor-pointer
                  ${partySize === size ? 'bg-[#c6aa6b]/20 border-[#c6aa6b]' : 'bg-neutral-800 border-neutral-700'}
                  border-2 transition-all duration-200 hover:border-[#c6aa6b]/50
                `}
              >
                <input
                  type="radio"
                  name="partySize"
                  value={size}
                  checked={partySize === size}
                  onChange={(e) => setPartySize(e.target.value)}
                  className="sr-only"
                />
                <span className="text-white">{size}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Event Details */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <label className="block text-white font-medium mb-2">Event Date</label>
            <input
              type="date"
              value={eventDate}
              onChange={(e) => setEventDate(e.target.value)}
              className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
            />
          </div>
          <div>
            <label className="block text-white font-medium mb-2">Start Time</label>
            <input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
            />
          </div>
          <div>
            <label className="block text-white font-medium mb-2">End Time</label>
            <input
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
            />
          </div>
        </div>

        {/* Additional Details */}
        <div>
          <label className="block text-white font-medium mb-2">Additional Details & Special Requests</label>
          <textarea
            rows={4}
            value={additionalDetails}
            onChange={(e) => setAdditionalDetails(e.target.value)}
            placeholder="Tell us more about your vision for the event..."
            className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors resize-none"
          />
        </div>

        {/* Contact Information */}
        <div>
          <h3 className="text-lg font-medium text-white mb-4">Contact Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-white font-medium mb-2">First Name</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
              />
            </div>
            <div>
              <label className="block text-white font-medium mb-2">Last Name</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
              />
            </div>
            <div>
              <label className="block text-white font-medium mb-2">Phone Number</label>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
              />
            </div>
            <div>
              <label className="block text-white font-medium mb-2">Email Address</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-3 rounded-lg bg-neutral-800 text-white border border-neutral-700 focus:border-[#c6aa6b] focus:ring-1 focus:ring-[#c6aa6b] transition-colors"
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="pt-4">
          <button
            type="submit"
            className="w-full bg-[#c6aa6b] hover:bg-[#b39461] text-white font-semibold py-4 px-8 rounded-lg transition-colors duration-200 shadow-lg"
          >
            Submit Inquiry
          </button>
          <p className="text-gray-400 text-sm text-center mt-4">
            * Appointments will not be locked in until we can get a hold of you to confirm.
          </p>
        </div>
      </form>
    </div>
  );
}
