import React from 'react';

interface ContentSectionProps {
  title: string;
  content: React.ReactNode;
  image: string;
  imageAlt: string;
  reverse?: boolean;
}

export function ContentSection({ title, content, image, imageAlt, reverse = false }: ContentSectionProps) {
  return (
    <div className={`group relative flex flex-col ${reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} gap-8 sm:gap-12 items-center mb-16 sm:mb-24 lg:mb-32`}>
      <div className="relative w-full lg:w-1/2">
        <div className="relative rounded-xl overflow-hidden bg-[#132F4C] p-2 transform transition-all duration-300 hover:scale-[1.02] hover:shadow-[0_12px_40px_-12px_rgba(0,127,255,0.3)]">
          <div className="absolute inset-0 bg-gradient-to-br from-[#007FFF]/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          <img 
            src={image} 
            alt={imageAlt} 
            className="w-full h-[250px] sm:h-[300px] md:h-[400px] object-cover rounded-lg transform transition-all duration-500 group-hover:scale-105"
          />
        </div>
        <div className="absolute -bottom-4 -right-4 w-24 h-24 bg-[#007FFF]/10 rounded-full blur-2xl" />
        <div className="absolute -top-4 -left-4 w-24 h-24 bg-[#66B2FF]/10 rounded-full blur-2xl" />
      </div>

      <div className="relative w-full lg:w-1/2 space-y-4 sm:space-y-6 transform transition-all duration-300 group-hover:translate-y-[-8px] text-center lg:text-left">
        <div className="relative">
          <h3 className="text-2xl sm:text-3xl font-bold text-[#66B2FF] tracking-tight mb-4">{title}</h3>
          <div className="absolute -top-6 -left-6 w-16 h-16 bg-[#007FFF]/5 rounded-full blur-xl" />
        </div>
        <div className="prose prose-lg max-w-none relative">
          {content}
        </div>
      </div>
    </div>
  );
}