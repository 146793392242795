import { PageSEO } from '../components/SEO/PageSEO';
import { Footer } from '../components/Footer/Footer';
import { EventForm } from '../components/EventForm/EventForm';
import { IMAGES } from '../config/images';

export function BookingPage() {
  return (
    <div className="min-h-screen bg-[#1a1d24]">
      <PageSEO 
        title="Book Your Private Event Venue in Houston Heights | Heights Social"
        description="Host your next event at Heights Social. Our versatile venue in Houston Heights is perfect for corporate events, weddings, social gatherings, and more. Request a booking today!"
        path="/book-private-event"
        image={IMAGES.HEADERS.PRIVATE_EVENTS}
      />

      <div className="pt-[120px] pb-20">
        <div className="max-w-7xl mx-auto px-4 text-center">
          {/* Feature Section */}
          <div className="mb-20 bg-[#23262f] p-6 md:p-12 rounded-lg">
            <div className="flex flex-col-reverse md:flex-row gap-6 md:gap-12 items-center justify-center">
              {/* Hero Image */}
              <div className="w-full md:w-1/2 flex justify-center">
                <img 
                  src={IMAGES.HEADERS.PRIVATE_EVENTS} 
                  alt="Private Events at Heights Social"
                  className="w-full h-auto md:h-[400px] object-cover rounded-lg shadow-lg"
                />
              </div>
              
              {/* Feature Text */}
              <div className="w-full md:w-1/2 flex flex-col justify-center text-center">
                <h1 className="text-4xl md:text-5xl font-bold text-[#c6aa6b] mb-6">
                  Book Your Event
                </h1>
                <p className="text-gray-300 text-lg leading-relaxed">
                  Create unforgettable moments in Houston's premier event space. Our versatile venue 
                  is perfect for corporate events, weddings, social gatherings, and more.
                </p>
              </div>
            </div>
          </div>

          {/* Event Form */}
          <div className="max-w-4xl mx-auto">
            <EventForm />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
