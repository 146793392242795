import React, { useState } from 'react';
import { PageSEO } from '../components/SEO/PageSEO';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { IMAGES } from '../config/images';

export function GalleryPage() {
  const [index, setIndex] = useState(-1);
  
  const images = IMAGES.GALLERY.map(url => ({
    src: url,
    width: 1600,
    height: 900
  }));

  return (
    <div className="min-h-screen bg-[#1a1d24]">
      <PageSEO 
        title="Event Venue Gallery | Heights Social Houston Heights"
        description="Explore our stunning event venue through our gallery. See why Heights Social is the perfect location for your next event in Houston Heights."
        path="/gallery"
        image={IMAGES.HEADERS.GALLERY}
      />
      <div className="pt-[120px]">
        <div 
          className="h-[60vh] min-h-[500px] relative flex items-center"
          style={{
            backgroundImage: `url("${IMAGES.HEADERS.GALLERY}")`,
            backgroundPosition: 'center calc(50% + 50px)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="absolute inset-0 bg-black/50" />
          <div className="relative container mx-auto px-4 z-10 text-center">
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 shadow-lg drop-shadow-[0_4px_8px_rgba(0,0,0,0.5)]">
              Gallery
            </h1>
            <p className="text-xl md:text-2xl text-white max-w-2xl mx-auto shadow-md drop-shadow-[0_4px_8px_rgba(0,0,0,0.5)]">
              Discover the perfect setting for your next exceptional event
            </p>
          </div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 py-20">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {images.map((image, idx) => (
              <button
                key={idx}
                onClick={() => setIndex(idx)}
                className="relative group aspect-square overflow-hidden rounded-lg bg-neutral-800"
              >
                <img
                  src={image.src}
                  alt={`Heights Social Gallery ${idx + 1}`}
                  className="object-cover w-full h-full transform transition-transform duration-300 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </button>
            ))}
          </div>

          <Lightbox
            index={index}
            slides={images}
            open={index >= 0}
            close={() => setIndex(-1)}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}