import React from 'react';
import { PageSEO } from '../components/SEO/PageSEO';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { ContactForm } from '../components/ContactForm/ContactForm';
import { FAQ } from '../components/FAQ/FAQ';
import { MapPin, Phone, Mail } from 'lucide-react';
import { IMAGES } from '../config/images';

export function ContactPage() {
  return (
    <div className="min-h-screen bg-[#1a1d24]">
      <PageSEO 
        title="Contact Heights Social | Houston Heights Event Venue"
        description="Get in touch with Heights Social, Houston Heights' premier event venue. Contact us for private events, VIP reservations, and general inquiries."
        path="/contact"
        image={IMAGES.HEADERS.CONTACT}
      />
      <div className="pt-[120px]">
        <div 
          className="h-[60vh] min-h-[500px] relative flex items-center"
          style={{
            backgroundImage: `url("${IMAGES.HEADERS.CONTACT}")`,
            backgroundPosition: 'center top',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="absolute inset-0 bg-black/50" />
          <div className="relative container mx-auto px-4 z-10 text-center">
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 shadow-lg drop-shadow-[0_4px_8px_rgba(0,0,0,0.5)]">
              Contact & FAQ
            </h1>
            <p className="text-xl md:text-2xl text-white max-w-2xl mx-auto shadow-md drop-shadow-[0_4px_8px_rgba(0,0,0,0.5)]">
              Get in touch with us
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-20">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div className="space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                <div className="bg-[#23262f] p-6 rounded-lg text-center">
                  <MapPin className="w-6 h-6 text-[#c6aa6b] mx-auto mb-3" />
                  <h3 className="text-white font-semibold mb-2">Address</h3>
                  <p className="text-gray-400">1213 W 20th St, Houston, TX 77008</p>
                </div>
                <div className="bg-[#23262f] p-6 rounded-lg text-center">
                  <Phone className="w-6 h-6 text-[#c6aa6b] mx-auto mb-3" />
                  <h3 className="text-white font-semibold mb-2">Phone</h3>
                  <p className="text-gray-400">(346) 200-9288</p>
                </div>
                <div className="bg-[#23262f] p-6 rounded-lg text-center">
                  <Mail className="w-6 h-6 text-[#c6aa6b] mx-auto mb-3" />
                  <h3 className="text-white font-semibold mb-2">Email</h3>
                  <p className="text-gray-400">
                    <a 
                      href="#contact-form" 
                      className="hover:text-[#c6aa6b] transition-colors"
                    >
                      Click Here
                    </a>
                  </p>
                </div>
              </div>
              <div id="contact-form">
                <ContactForm />
              </div>
            </div>

            <div className="h-[600px] bg-[#23262f] rounded-lg overflow-hidden shadow-lg">
              <iframe 
                id="gmap_canvas"
                width="100%" 
                height="100%" 
                frameBorder="0" 
                scrolling="no" 
                marginHeight={0} 
                marginWidth={0} 
                src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=1213%20West%2020th%20Street%20Houston+(Heights%20Social)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                className="filter grayscale"
                title="Heights Social Location"
              />
            </div>
          </div>

          <div className="mt-20">
            <h2 className="text-3xl font-bold text-[#c6aa6b] mb-8">Frequently Asked Questions</h2>
            <FAQ />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}