import { Link } from 'react-router-dom';
import { MapPin, Phone, Facebook, Instagram } from 'lucide-react';

export function Footer() {
  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Book Private Event', href: '/book-private-event' },
    { name: 'Gallery', href: '/gallery' },
    { name: 'Club Events', href: '/club-events' },
    { name: 'VIP Tables', href: '/club-events#vip-tables' },
    { name: 'Contact & FAQ', href: '/contact' },
  ];

  return (
    <footer className="bg-[#14181c] py-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-center md:text-left">
          {/* Venue Info */}
          <div>
            <h3 className="text-xl font-semibold text-[#c6aa6b] mb-4">Heights Social</h3>
            <div className="space-y-2 text-gray-400">
              <div className="flex justify-center md:justify-start items-center">
                <MapPin className="w-5 h-5 mr-2" />
                <p>1213 W 20th St</p>
              </div>
              <p>Houston, TX 77008</p>
              <div className="flex justify-center md:justify-start items-center">
                <Phone className="w-5 h-5 mr-2" />
                <p>(346) 200-9288</p>
              </div>
            </div>
          </div>

          {/* Navigation */}
          <div>
            <h3 className="text-xl font-semibold text-[#c6aa6b] mb-4">Navigation</h3>
            <ul className="space-y-2">
              {navigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className="text-gray-400 hover:text-[#c6aa6b] transition-colors"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Social Links */}
          <div>
            <h3 className="text-xl font-semibold text-[#c6aa6b] mb-4">Connect With Us</h3>
            <div className="flex justify-center md:justify-start space-x-6">
              <a
                href="https://www.facebook.com/heightssocialhouston"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-[#c6aa6b] transition-colors"
                aria-label="Facebook"
              >
                <Facebook className="w-6 h-6" />
              </a>
              <a
                href="https://www.instagram.com/heightssocialhouston"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-[#c6aa6b] transition-colors"
                aria-label="Instagram"
              >
                <Instagram className="w-6 h-6" />
              </a>
              <a
                href="https://g.co/kgs/TVmNXvT"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-[#c6aa6b] transition-colors"
                aria-label="Google"
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                >
                  <path
                    d="M24 9.5c3.69 0 6.43 1.6 7.9 2.93l5.82-5.84C34.62 3.87 29.89 2 24 2 14.73 2 7.07 7.71 4 15.37l6.92 5.39c1.6-6.5 7.36-11.26 13.08-11.26z"
                  />
                  <path
                    d="M24 44c5.24 0 9.68-1.73 12.91-4.72l-6.19-5.27C28.84 35.82 26.45 37 24 37c-5.57 0-10.3-3.79-12-8.91L5 33.56C8.23 40.55 15.44 44 24 44z"
                  />
                  <path
                    d="M43.61 20.78H24v7.33h11.3c-1.43 3.88-5.18 6.68-11.3 6.68-7.04 0-13.2-5.87-12.68-13.23.63-8.06 8.36-12.48 16.06-11.62 3.51.38 6.62 1.87 8.78 4.14l6.22-6.22C38.99 5.34 32.01 2.01 24 2.01 11.74 2 2.14 11.39 2.01 23.64c-.13 12.02 9.5 21.94 21.48 22.34 13.43.43 22.93-9.93 22.93-22.08 0-1.3-.13-2.41-.42-3.56-.18-.65-.56-1.52-.93-2.13z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-neutral-800 text-gray-500 text-center">
          <p>&copy; {new Date().getFullYear()} Heights Social. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
