import React from 'react';
import { Link } from 'react-router-dom';

interface MobileNavLinkProps {
  to: string;
  onClick: () => void;
  children: React.ReactNode;
}

export function MobileNavLink({ to, onClick, children }: MobileNavLinkProps) {
  const handleClick = (e: React.MouseEvent) => {
    onClick();
    
    // Handle hash navigation
    const [_, hash] = to.split('#');
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        element?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  };

  return (
    <Link 
      to={to}
      onClick={handleClick}
      className="text-white hover:text-[#c6aa6b] transition-colors font-light uppercase tracking-wider"
    >
      {children}
    </Link>
  );
}