import React from 'react';
import { PageSEO } from '../components/SEO/PageSEO';
import { MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ContentSection } from '../components/ContentSection/ContentSection';
import { IMAGES } from '../config/images';

export function Home() {
  return (
    <>
      <PageSEO 
        title="Houston's Premier Private Event Venue | Heights Social"
        description="Discover Heights Social, the top private event venue in Houston Heights. Perfect for weddings, corporate events, and private parties. Book your event today!"
        path="/"
      />
      
      <div 
        className="relative min-h-[calc(100vh-120px)] mt-[120px]"
        style={{
          backgroundImage: `url("${IMAGES.HEADERS.HERO}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="absolute inset-0 bg-black/40" />
        
        <div className="relative z-10 flex items-center justify-center min-h-[calc(100vh-120px)] px-4">
          <div className="text-center max-w-[90%] sm:max-w-2xl">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-6 shadow-lg drop-shadow-[0_4px_8px_rgba(0,0,0,0.5)]">
              Heights Social
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl text-white mx-auto mb-4 shadow-md drop-shadow-[0_4px_8px_rgba(0,0,0,0.5)]">
              Houston Heights' Most Elegant Private Event Venue
            </p>
            <div className="flex items-center justify-center text-white shadow-md drop-shadow-[0_4px_8px_rgba(0,0,0,0.5)]">
              <MapPin className="w-5 h-5 mr-2 flex-shrink-0" />
              <p className="text-sm sm:text-base">1213 W 20th St, Houston, TX 77008</p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-20">
        <ContentSection
          title="LUXURY MEETS VERSATILITY"
          content={
            <div className="space-y-4">
              <p className="text-gray-300 text-base sm:text-lg leading-relaxed">
                Welcome to Heights Social, an architectural masterpiece in the heart of Houston Heights 
                offering 8,500 square feet of meticulously designed event space. As the area's first 
                fully indoor, climate-controlled venue, we provide an unparalleled setting for 
                sophisticated private events throughout the year.
              </p>
              <p className="text-gray-300 text-base sm:text-lg leading-relaxed">
                Our grand Atrium welcomes guests with a stunning 101-inch Restoration Hardware chandelier, 
                creating an immediate sense of elegance and luxury. Natural light streams through 
                strategically placed skylights, illuminating our contemporary design and premium finishes.
              </p>
            </div>
          }
          image={IMAGES.CONTENT.ATRIUM}
          imageAlt="Heights Social Atrium"
        />

        <ContentSection
          title="EXCEPTIONAL EVENT EXPERIENCES"
          content={
            <div className="space-y-4">
              <p className="text-gray-300 text-base sm:text-lg leading-relaxed">
                Whether you're planning a corporate gathering, wedding celebration, or social event, 
                Heights Social offers a sophisticated backdrop complemented by state-of-the-art 
                amenities. Our 46-foot LED wall and integrated display system provide unlimited 
                possibilities for customizing your event's ambiance and presentations.
              </p>
              <p className="text-gray-300 text-base sm:text-lg leading-relaxed">
                Our dedicated event planning team works closely with Houston's finest caterers to 
                create custom menus tailored to your preferences. From intimate cocktail receptions 
                to grand celebrations, we ensure every detail exceeds expectations.
              </p>
            </div>
          }
          image={IMAGES.CONTENT.EVENTS}
          imageAlt="Heights Social Events"
          reverse
        />

        <ContentSection
          title="MODERN AMENITIES & SERVICES"
          content={
            <div className="space-y-4">
              <p className="text-gray-300 text-base sm:text-lg leading-relaxed">
                Our venue features a professional-grade sound system, perfect for presentations or 
                entertainment. The expansive main bar, staffed by skilled mixologists, offers craft 
                cocktails and premium beverage service. Our full-service kitchen facilities support 
                seamless catering operations for events of any scale.
              </p>
              <p className="text-gray-300 text-base sm:text-lg leading-relaxed">
                With flexible floor plans, dedicated event coordinators, and comprehensive audio-visual 
                capabilities, Heights Social transforms to accommodate your unique vision. Our venue 
                comfortably hosts up to 400 guests while maintaining an intimate atmosphere.
              </p>
            </div>
          }
          image={IMAGES.CONTENT.MAIN_ROOM}
          imageAlt="Heights Social Main Room"
        />

        <div className="text-center mt-12 sm:mt-16 lg:mt-20">
          <Link
            to="/book-private-event"
            className="inline-block bg-[#c6aa6b] hover:bg-[#b39461] text-white font-semibold px-6 sm:px-8 py-3 sm:py-4 rounded-lg transition-colors duration-200 shadow-lg text-base sm:text-lg"
          >
            PLAN YOUR EVENT
          </Link>
        </div>
      </div>
    </>
  );
}