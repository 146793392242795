import React from 'react';
import { PageSEO } from '../components/SEO/PageSEO';
import { ContentSection } from '../components/ContentSection/ContentSection';
import { VIPForm } from '../components/VIPForm/VIPForm';
import { IMAGES } from '../config/images';

export function ClubEventsPage() {
  return (
    <>
      <PageSEO 
        title="Club Events & VIP Tables | Heights Social Houston"
        description="Experience Houston's premier nightlife at Heights Social. Book VIP tables for Industry Night Wednesdays and weekend events. Elevated atmosphere in the Heights."
        path="/club-events"
        image={IMAGES.HEADERS.CLUB}
      />
      
      {/* Hero Section */}
      <div 
        className="h-[60vh] min-h-[500px] relative flex items-center mt-[120px]"
        style={{
          backgroundImage: `url("${IMAGES.HEADERS.CLUB}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className="absolute inset-0 bg-black/50" />
        <div className="relative container mx-auto px-4 z-10 text-center">
          <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 shadow-lg drop-shadow-[0_4px_8px_rgba(0,0,0,0.5)]">
            Club Events
          </h1>
          <p className="text-xl md:text-2xl text-white max-w-3xl mx-auto mb-8 shadow-md drop-shadow-[0_4px_8px_rgba(0,0,0,0.5)]">
            The Heights' Premier Nightlife Destination
          </p>
          <a 
            href="#vip-tables"
            className="inline-block bg-[#c6aa6b] hover:bg-[#b39461] text-white font-bold px-8 py-4 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg"
          >
            VIP TABLES
          </a>
        </div>
      </div>

      {/* Glitterball NYE Section */}
      <div className="relative bg-gradient-to-b from-[#1a1d24] to-black py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            {/* Image */}
            <div className="relative">
              <img 
                src={IMAGES.HEADERS.GLITTERBALL}
                alt="Glitterball New Years Eve at Heights Social"
                className="rounded-lg shadow-2xl w-full"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-lg"></div>
            </div>
            
            {/* Content */}
            <div className="text-center md:text-left">
              <h2 className="text-4xl md:text-5xl font-bold text-white mb-6 bg-clip-text text-transparent bg-gradient-to-r from-[#FFD700] to-[#FFA500]">
                Glitterball New Years Eve
              </h2>
              <p className="text-xl text-gray-300 mb-8 leading-relaxed">
                Ring in 2024 in style at Houston Heights' most elegant venue. Experience a night of 
                glamour, champagne, and unforgettable moments. Featuring premium entertainment, 
                countdown celebration, and our signature VIP service.
              </p>
              
              {/* CTAs */}
              <div className="flex flex-col sm:flex-row justify-center md:justify-start gap-4">
                <a 
                  href="https://www.seetickets.us/event/Glitterball-NYE-Tuesday-Dec-21st-Heights-Social/630362"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-[#c6aa6b] hover:bg-[#b39461] text-white font-bold px-8 py-4 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg text-center"
                >
                  GET TICKETS
                </a>
                <a 
                  href="#vip-tables"
                  className="inline-block bg-transparent border-2 border-[#c6aa6b] hover:border-[#b39461] text-[#c6aa6b] hover:text-[#b39461] font-bold px-8 py-4 rounded-lg transition-all duration-300 transform hover:scale-105 shadow-lg text-center"
                >
                  BOOK VIP TABLE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Regular Content Sections */}
      <div className="max-w-7xl mx-auto px-4 py-20">
        <ContentSection
          title="Industry Night Wednesdays"
          content={
            <p className="text-gray-300 text-lg leading-relaxed">
              Join us every Wednesday for Houston's most exclusive industry night. Experience premium 
              service, exceptional music, and an unforgettable atmosphere. Network with Houston's 
              nightlife professionals while enjoying our state-of-the-art sound system and stunning 
              LED displays.
            </p>
          }
          image={IMAGES.CONTENT.CLUB_1}
          imageAlt="Industry Night at Heights Social"
        />

        <ContentSection
          title="Weekend Nights"
          content={
            <p className="text-gray-300 text-lg leading-relaxed">
              Experience Houston's premier nightlife destination Friday through Sunday. Our weekend events 
              feature top DJs, premium bottle service, and an upscale atmosphere that sets us apart. 
              With our 40-foot LED wall and professional sound system, every weekend at Heights Social 
              is an immersive experience.
            </p>
          }
          image={IMAGES.CONTENT.CLUB_2}
          imageAlt="Weekend Events at Heights Social"
          reverse
        />

        <ContentSection
          title="VIP Experience"
          content={
            <p className="text-gray-300 text-lg leading-relaxed">
              Elevate your night with our premium VIP service. Enjoy dedicated table service, premium 
              spirits, and the best views of the venue. Our VIP experience includes priority entry, 
              personal service staff, and a curated selection of premium bottles. Perfect for birthdays, 
              celebrations, or when you want to make your night extra special.
            </p>
          }
          image={IMAGES.CONTENT.CLUB_3}
          imageAlt="VIP Experience at Heights Social"
        />

        <div id="vip-tables" className="scroll-mt-32 mt-32">
          <h2 className="text-4xl font-bold text-[#c6aa6b] text-center mb-12">Reserve Your VIP Experience</h2>
          <VIPForm />
        </div>
      </div>
    </>
  );
}