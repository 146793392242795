import { useState } from 'react';
import { Menu, X, MapPin, Phone, Facebook, Instagram } from 'lucide-react';
import { MobileNavLink } from './MobileNavLink';
import { Link } from 'react-router-dom';
import { IMAGES } from '../../config/images';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="fixed top-0 left-0 right-0 w-full z-50 bg-[#14181c]">
      <div className="max-w-7xl mx-auto px-4 h-[120px] flex items-center justify-between relative">
        {/* Toggle Menu Button */}
        <button 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white hover:text-[#c6aa6b] transition-colors px-4 sm:px-8 text-sm sm:text-base"
          aria-label="Toggle menu"
        >
          {isMenuOpen ? (
            <X className="h-4 w-4 sm:h-6 sm:w-6" />
          ) : (
            <Menu className="h-4 w-4 sm:h-6 sm:w-6" />
          )}
        </button>

        {/* Center Logo */}
        <Link to="/" className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="bg-[#20252d] border-2 border-[#14181c] p-3 w-[100px] h-[100px] sm:p-4 sm:w-[150px] sm:h-[150px] flex items-center justify-center">
            <img 
              src={IMAGES.LOGO}
              alt="Heights Social"
              className="w-full h-auto"
            />
          </div>
        </Link>

        {/* Book Event Button */}
        <Link 
          to="/book-private-event"
          className="bg-[#c6aa6b] hover:bg-[#b39461] text-white px-4 py-1 sm:px-6 sm:py-2 rounded transition-colors text-sm sm:text-base"
        >
          BOOK EVENT
        </Link>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="fixed inset-0 bg-[#23262f]/90 z-50">
            <button 
              onClick={() => setIsMenuOpen(false)}
              className="absolute top-8 right-8 text-white hover:text-[#c6aa6b] transition-colors"
              aria-label="Close menu"
            >
              <X className="h-8 w-8" />
            </button>

            <div className="h-full flex flex-col items-center justify-center space-y-12 p-4">
              <img 
                src={IMAGES.LOGO}
                alt="Heights Social"
                className="w-36 h-auto sm:w-48"
              />

              <nav className="flex flex-col items-center space-y-6 text-lg sm:text-2xl">
                <MobileNavLink to="/" onClick={() => setIsMenuOpen(false)}>
                  HOME
                </MobileNavLink>
                <MobileNavLink to="/book-private-event" onClick={() => setIsMenuOpen(false)}>
                  BOOK PRIVATE EVENT
                </MobileNavLink>
                <MobileNavLink to="/gallery" onClick={() => setIsMenuOpen(false)}>
                  GALLERY
                </MobileNavLink>
                <MobileNavLink to="/club-events" onClick={() => setIsMenuOpen(false)}>
                  CLUB EVENTS
                </MobileNavLink>
                <MobileNavLink to="/club-events#vip-tables" onClick={() => setIsMenuOpen(false)}>
                  VIP TABLES
                </MobileNavLink>
                <MobileNavLink to="/contact" onClick={() => setIsMenuOpen(false)}>
                  CONTACT & FAQ
                </MobileNavLink>
              </nav>

              <div className="text-center text-[#c9ad6d] space-y-2">
                <div className="flex items-center justify-center space-x-2">
                  <MapPin className="w-5 h-5" />
                  <p>1213 W 20th St</p>
                </div>
                <p>Houston, TX 77008</p>
                <div className="flex items-center justify-center space-x-2">
                  <Phone className="w-5 h-5" />
                  <p>(346) 200-9288</p>
                </div>
              </div>

              <div className="flex space-x-6">
                <a 
                  href="https://www.facebook.com/heightssocialhouston"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white hover:text-[#c6aa6b] transition-colors"
                >
                  <Facebook className="w-8 h-8" />
                </a>
                <a 
                  href="https://www.instagram.com/heightssocialhouston"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white hover:text-[#c6aa6b] transition-colors"
                >
                  <Instagram className="w-8 h-8" />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
