// Image URL configuration
export const IMAGES = {
  // Headers
  HEADERS: {
    GALLERY: '/images/headers/galleryheader1.jpg',
    CLUB: '/images/headers/clubheader.jpg',
    CONTACT: '/images/headers/contactheader.jpg',
    HERO: '/images/headers/heronew2.jpg',
    PRIVATE_EVENTS: '/images/misc/privateeventsheaderm.jpg',
    GLITTERBALL: '/images/misc/glitterball584.jpg'
  },
  
  // Logo
  LOGO: '/images/misc/HS150.png',
  
  // Gallery images
  GALLERY: [
    '/images/gallery/heighsgallery39.jpg',
    '/images/gallery/heighsgallery36.jpg',
    '/images/gallery/heighsgallery33.jpg',
    '/images/gallery/heighsgallery31.jpg',
    '/images/gallery/heighsgallery30.jpg',
    '/images/gallery/heighsgallery27.jpg',
    '/images/gallery/heighsgallery26.jpg',
    '/images/gallery/heighsgallery24.jpg',
    '/images/gallery/heighsgallery21.jpg',
    '/images/gallery/heighsgallery19.jpg',
    '/images/gallery/heighsgallery17.jpg',
    '/images/gallery/heighsgallery16.jpg',
    '/images/gallery/heighsgallery15.jpg',
    '/images/gallery/heighsgallery14.jpg',
    '/images/gallery/heighsgallery10.jpg',
    '/images/gallery/heighsgallery9.jpg',
    '/images/gallery/heighsgallery7.jpg',
    '/images/gallery/heighsgallery5.jpg',
    '/images/gallery/heighsgallery2.jpg',
    '/images/gallery/heighsgallery1.jpg'
  ],
  
  // Content images
  CONTENT: {
    ATRIUM: '/images/misc/atrium1.jpg',
    EVENTS: '/images/misc/outside500.jpg',
    MAIN_ROOM: '/images/misc/main.jpg',
    CLUB_1: '/images/misc/club1.jpg',
    CLUB_2: '/images/misc/club2.jpg',
    CLUB_3: '/images/misc/club3.jpg'
  }
};