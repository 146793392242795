import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const faqSections = [
  {
    title: "General Questions",
    questions: [
      {
        question: "Where are you located?",
        answer: "We're located at 1213 W 20th St, Houston, TX 77008, in the heart of the Heights neighborhood."
      },
      {
        question: "What are your hours of operation?",
        answer: "Monday, Tuesday, Thursday: CLOSED\nWednesday, Friday-Sunday: 6PM-2AM"
      },
      {
        question: "What's the age requirement and cover charge?",
        answer: "We are strictly 21+ for all events. Most club nights have no cover charge, though special events (like Halloween weekend) may have a cover charge."
      },
      {
        question: "What's the parking situation?",
        answer: "We offer convenient valet service, and paid parking is also available in the area."
      },
      {
        question: "I left my card/lost an item, how do I retrieve it?",
        answer: "Please visit us during our opening hours (Wed, Fri-Sun from 6PM) and speak with a bartender to retrieve your belongings."
      }
    ]
  },
  {
    title: "Private Events FAQ",
    questions: [
      {
        question: "What types of private events do you host?",
        answer: "We host a variety of events including corporate gatherings, networking receptions, wedding receptions, baby showers, bridal showers, fundraisers, and charity events."
      },
      {
        question: "What's included in the venue rental?",
        answer: "Our comprehensive rental package includes:\n\n• Full event space\n• Complete bar staff\n• Professional security team\n• Advanced lighting and sound systems\n• Visual technology with consultation\n• Dedicated venue management\n• Valet staff\n• Catering consultation\n• Custom branded visuals throughout the space"
      },
      {
        question: "Do you provide catering services?",
        answer: "Yes, our in-house catering team can create any menu you envision. We can either suggest curated menu options or work directly with your ideas to bring them to life. Our culinary team is flexible and skilled in crafting menus that perfectly match your event's style and requirements."
      },
      {
        question: "What's the capacity for private events?",
        answer: "Our versatile space can accommodate 400-600 guests, depending on your preferred event layout and setup."
      },
      {
        question: "How far in advance should I book?",
        answer: "While we can accommodate bookings on shorter notice if dates are available, we recommend securing your date as early as possible to ensure availability. Popular dates tend to book quickly, especially during peak seasons."
      }
    ]
  },
  {
    title: "Club Events FAQ",
    questions: [
      {
        question: "What are your signature nights?",
        answer: "We feature Industry Night Wednesdays for Houston's nightlife insiders, and Weekend Takeover (Friday-Sunday) with top DJs and elevated bottle service."
      },
      {
        question: "What's the dress code?",
        answer: "We maintain an elevated atmosphere with a strict dress code. Entry is at the doorman's discretion to ensure the best experience for all guests."
      },
      {
        question: "Do you offer bottle service?",
        answer: "Yes, we offer premium bottle service with VIP table reservations. Each reservation includes dedicated service staff and a curated selection of premium spirits."
      },
      {
        question: "Can I host a birthday celebration?",
        answer: "Absolutely! We specialize in birthday celebrations with VIP table service. Contact us through our VIP Tables form to arrange your special night."
      },
      {
        question: "What kind of music do you play?",
        answer: "Our resident and guest DJs curate dynamic sets featuring hip-hop, house, and top 40 hits. Each night brings its own unique energy and sound."
      }
    ]
  }
];

export function FAQ() {
  const [openSection, setOpenSection] = useState<number | null>(0);
  const [openQuestion, setOpenQuestion] = useState<number | null>(null);

  return (
    <div className="space-y-6">
      {faqSections.map((section, sectionIndex) => (
        <div key={sectionIndex} className="bg-[#23262f] rounded-lg overflow-hidden">
          <button
            className="w-full px-6 py-4 text-left flex items-center justify-between bg-[#2b2e38]"
            onClick={() => setOpenSection(openSection === sectionIndex ? null : sectionIndex)}
          >
            <span className="text-xl font-medium text-[#c6aa6b]">{section.title}</span>
            <ChevronDown
              className={`w-5 h-5 text-[#c6aa6b] transition-transform duration-200 ${
                openSection === sectionIndex ? 'transform rotate-180' : ''
              }`}
            />
          </button>
          
          <div
            className={`overflow-hidden transition-all duration-200 ${
              openSection === sectionIndex ? 'max-h-[2000px]' : 'max-h-0'
            }`}
          >
            <div className="p-4 space-y-2">
              {section.questions.map((faq, questionIndex) => (
                <div key={questionIndex} className="border-b border-neutral-700 last:border-0">
                  <button
                    className="w-full px-4 py-4 text-left flex items-center justify-between"
                    onClick={() => setOpenQuestion(openQuestion === questionIndex ? null : questionIndex)}
                  >
                    <span className="text-lg font-medium text-white">{faq.question}</span>
                    <ChevronDown
                      className={`w-5 h-5 text-[#c6aa6b] transition-transform duration-200 ${
                        openQuestion === questionIndex ? 'transform rotate-180' : ''
                      }`}
                    />
                  </button>
                  <div
                    className={`px-4 overflow-hidden transition-all duration-200 ${
                      openQuestion === questionIndex ? 'max-h-96 pb-4' : 'max-h-0'
                    }`}
                  >
                    <p className="text-gray-400 whitespace-pre-line">{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}